import React from "react"
import LayoutDefault from "../components/layouts/default"

import Features from "../components/parts/body/features"


export default () => (

  <LayoutDefault>
    <Features></Features>
  </LayoutDefault>

)
